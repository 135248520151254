import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import {
  Row,
  CopyrightRow,
  CopyrightParagraph,
  BrandContainer,
  StyledImg,
  /*IconsContainer,
  IconContainer,
  StyledIconImg,
  StyledIconLink,*/
} from './footer.css';

const Footer = ({ logo /*, icons*/ }) => {
  return (
    <>
      <Row>
        <BrandContainer>
          <StyledImg
            fluid={logo ? logo.childImageSharp.fluid : {}}
            alt="Dott.ssa Lara Bartali - Fisioterapia"
          />
        </BrandContainer>
        {/*<IconsContainer>
          {icons.map((item, i) => (
            <IconContainer key={'icon-container-' + i.toString()}>
              <StyledIconImg
                fluid={item.image ? item.image.childImageSharp.fluid : {}}
                key={'icon-' + i.toString()}
              />
              <StyledIconLink key={'link-' + i.toString()} href={item.link}>
                {item.title}
              </StyledIconLink>
            </IconContainer>
          ))}
        </IconsContainer>*/}
      </Row>
      <CopyrightRow>
        <CopyrightParagraph>
          Copyright© Dott.ssa Lara Bartali, Fisioterapista - Tutti i diritti
          riservati - P. IVA 06210460488
          <br />
          <a
            href="https://www.iubenda.com/privacy-policy/31619758"
            className="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe"
            style={{
              color: 'white',
            }}
            title="Privacy Policy "
          >
            Privacy Policy
          </a>
          &nbsp;
          <a
            href="https://www.iubenda.com/privacy-policy/31619758/cookie-policy"
            className="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe iub-body-embed"
            style={{
              color: 'white',
            }}
            title="Cookie Policy"
          >
            Cookie Policy
          </a>
        </CopyrightParagraph>
      </CopyrightRow>
    </>
  );
};

const FooterWithQuery = props => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          homeJson {
            landing {
              logo {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          footerJson {
            icons {
              title
              link
              image {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Footer
          logo={data.homeJson.landing.logo}
          icons={data.footerJson.icons}
          {...props}
        />
      )}
    />
  );
};

Footer.propTypes = {
  logo: PropTypes.object.isRequired,
  icons: PropTypes.object.isRequired,
  link: PropTypes.string,
  title: PropTypes.string,
};

FooterWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FooterWithQuery;
