import styled from 'styled-components';
import { Link } from 'gatsby';
import MEDIA from 'helpers/mediaTemplates';

export const StyledLink = styled(Link)`
  color: #1a5d8f;
  font-size: 3rem;
  ::hover {
    color: #1a5d8f;
  }
  ${MEDIA.TABLET`
    font-size: 2.5rem;
  `};
`;

export const StyledStaticLink = styled.a`
  color: #1a5d8f;
  font-size: 3rem;
  ::hover {
    color: #1a5d8f;
  }
  ${MEDIA.TABLET`
    font-size: 2.5rem;
  `};
`;

export const Container = styled.nav`
  ${MEDIA.TABLET`
    margin: auto;
  `};
  ul {
    display: flex;
    list-style: none;
    padding: 0;

    ${MEDIA.TABLET`
      flex-direction: row;
      align-items: center;
    `};

    li {
      text-transform: uppercase;
      text-align: center;
      display: block;
      font-size: 1.3rem;

      ${MEDIA.TABLET`
        font-size: 1.15rem;
      `};

      a {
        display: inline-block;
      }

      & + li {
        margin-left: 2rem;
        ${MEDIA.TABLET`
          margin-left: 0;
        `};
      }
    }
  }
`;
