import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const StyledContainerToAnimate = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background: none;
  margin-bottom: -15vh;
`;

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  min-height: 10vh;

  ${MEDIA.TABLET`
    padding: 0;
  `}

  a {
    color: #1a5d8f;
    transition: 0.2s ease;
    text-decoration: none;
    padding: 1rem;

    &:hover {
      background: #114062;
      color: #fff;
    }
  }
`;
