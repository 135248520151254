import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import posed from 'react-pose';
import { Container, StyledContainerToAnimate } from './header.css';
import Nav from 'components/header/nav';
import { Consumer } from 'store/createContext';

// Example of a component-specific page transition
const AnimatedContainer = posed(StyledContainerToAnimate)({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});
//this.state.scroll > 0.05 ? ({backgroundColor: "#faf8f6"}) : {}

const Header = () => (
  <AnimatedContainer>
    <Consumer>
      {({ headerBackground, firstStyle, secondStyle }) => (
        <Container style={headerBackground ? firstStyle : secondStyle}>
          <Nav />
        </Container>
      )}
    </Consumer>
  </AnimatedContainer>
);

const HeaderWithQuery = props => {
  return (
    <StaticQuery
      query={graphql`
        query HeaderQuery {
          headerJson {
            title
            logo {
              childImageSharp {
                fluid(maxWidth: 500, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Header
          title={data.headerJson.title}
          image={data.headerJson.logo}
          {...props}
        />
      )}
    />
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
};

HeaderWithQuery.propTypes = {
  props: PropTypes.node.isRequired,
};

//export default HeaderWithQuery;
export default HeaderWithQuery;
